import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 1,
  selectedNFTList: [],
  // selectedNFTList: [{
  //   name: "",
  //   description: "",
  //   image: "",
  //   wrapper: {},
  //   attributes:[],
  //   uri: "https://wnfts.xp.network/w/61dc4a5c4298fe05d76f92f4",
  //   native: {
  //     chainId: 19,
  //     tokenId: 9,
  //     owner: "0xb6C8748115d23Eb1c6d59Cb83eAe051b56ef75c7",
  //     contract: "0xFC2b3dB912fcD8891483eD79BA31b8E5707676C9",
  //     symbol: "XPNFT",
  //     name: "XpWrappedNft",
  //     uri: "https://wnfts.xp.network/w/61dc4a5c4298fe05d76f92f4",
  //     contractType: "ERC721"
  //   },
  //   txn: "0x2446f1fd773fbb9f080e674b60c6a033c7ed7427b8b9413cf28a2a4a6da9b56c"
  // }],
  NFTListView: false,
  approvedNFTList: [],
  nftsToWhitelist: [],
  // txnHashArr: [{hash: "0x2446f1fd773fbb9f080e674b60c6a033c7ed7427b8b9413cf28a2a4a6da9b56c"}],
  txnHashArr: [],
  fees : 0,
  currentTx: 0,
  bigLoader : true,
  // approved: ''
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setTo(state, action) {
      state.to = action.payload;
    },
    setFrom(state, action) {
        state.from = action.payload;
    },
    setChainModal(state, action){
        state.showChainModal = action.payload;
    },
    setDepartureOrDestination(state, action){
      state.departureOrDestination = action.payload
    },
    setChainSearch(state, action){
      state.chainSearch = action.payload
    },
    setStep(state, action){
      state.step = action.payload
    },
    setMetaMask(state, action){
      state.MetaMask = action.payload
    },
    setWidget(state, action) {
      state.widget = action.payload
    },
    setAccount(state, action){
      state.account = action.payload
    },
    setNFTList(state, action){
      state.NFTList = action.payload
    },
    setSelectedNFTList(state, action){
      state.selectedNFTList = [...state.selectedNFTList, action.payload]
    },
    cleanSelectedNFTList(state, action){
      state.selectedNFTList = []
    },
    removeFromSelectedNFTList(state, action){
      const {tokenId, contract, chainId} = action.payload.native
      state.selectedNFTList = state.selectedNFTList.filter(n => !(
        n.native.tokenId === tokenId && n.native.contract === contract && n.native.chainId === chainId
      ))
    },
    setSearchNFTList(state, action){
      state.NFTListSearch = action.payload
    },
    allSelected(state){
      state.selectedNFTList = state.NFTList 
    },
    setNFTsListView(state){
      state.NFTListView = !state.NFTListView
    },
    updateApprovedNFTs(state, action) {
      const {tokenId, contract, chainId} = action.payload.native
      const isInApprovedNFTs = state.approvedNFTList.filter(n => n.native.tokenId === tokenId && n.native.contract === contract && chainId === n.native.chainId )[0]
      if(!isInApprovedNFTs)
      state.approvedNFTList = [...state.approvedNFTList, action.payload]
    },
    setApproved(state, action){
      
      state.approved = action.payload
    },
    setReceiver(state, action){
      state.receiver = action.payload
    },
    cleanTxnHashArr(state){
      state.txnHashArr = state.txnHashArr?.initialState ? state.txnHashArr?.initialState : []
    },
    setTxnHash(state, action){
      const {nft, txn} = action.payload
      const { tokenId, contract, chainId } = nft.native
      state.txnHashArr = [...state.txnHashArr, action.payload.txn]
      
      state.selectedNFTList = state.selectedNFTList.map(n => {
        const {native} = n
        if(native.tokenId === tokenId && native.contract === contract && native.chainId === chainId) {
          n.txn = txn
        }
        return n
      })
    },
    setWrongNetwork(state, action){
      state.wrongNetwork = action.payload
    },
    setMetaMaskActive(state, action){
      state.metaMaskActive = action.payload
    },
    setReset(){
      return initialState
    },
    setElrondAccount(state, action){
      state.elrondAccount = action.payload
    },
    setMaiarProvider(state, action){
      state.maiarProvider = action.payload
    },
    removeAlgorandClaimable(state, action) {
      state.algorandClaimables = state.algorandClaimables.filter(n => n.nftId !== action.payload)
    },
    setOnMaiar(state, action){
      state.onMaiar = action.payload
    },
    setTronWallet(state, action){
      state.tronWallet = action.payload
    },
    setConfirmMaiarMob(state, action){
      state.confirmMaiarMob = action.payload
    },
    setSwitchDestination(state, action){
      state.switchDestination = action.payload
    },
    setAccountModal(state, action){
      state.accountModal = action.payload
    },
    setBigLoader(state, action){
      state.bigLoader = action.payload
    },
    setApproveLoader(state, action){
      state.approveLoader = action.payload
    },
    setTronLink(state, action){
      state.tronLink = action.payload
    },
    setOnWC(state, action){
      state.WalletConnect = action.payload
    },
    setWC(state, action){
      state.WCProvider = action.payload
    },
    setError(state, action){
      state.error = action.payload
    },
    setTronPopUp(state, action){
      state.tronPopUp = action.payload
    },
    setBigNumFees(state, action){
      state.bigNumberFees = action.payload
    },
    setTronLoginError(state, action){
      state.tronLoginError = action.payload
    },
    setTrustWallet(state, action){
      state.trustWallet = action.payload
    },
    setFactory(state,action) {
      state.factory = action.payload
    },
    connectAlgorandWalletClaim(state, action) {
      state.connectClaimAlgorand = action.payload
    },
    claimAlgorandPopup(state, action) {
      // claim from success popup
      state.algorandClaimPopup = action.payload
    },
    setAlgoSigner(state, action){
      state.AlgoSigner = action.payload
    },
    setAlgorandAccount(state, action){
      state.algorandAccount = action.payload
    },
    setShowAbout(state, action){
      state.about = action.payload
    },
    setShowVideo(state, action){
      state.video = action.payload
    },
    setAlgorandClaimables(state, action) {
      state.algorandClaimables = action.payload
    },
    setAlgorandWallet(state, action){
      state.AlgorandWallet = action.payload
    },
    setMyAlgo(state, action){
      state.MyAlgo = action.payload
    },
    setNFTsToWhitelist(state, action){
      state.nftsToWhitelist = [...state.nftsToWhitelist, action.payload]
    },
    removeFromNotWhiteListed(state){
      state.nftsToWhitelist.shift()
    },
    setTransferLoaderModal(state, action){
      state.transferModalLoader = action.payload
    },
    setValidatorsInf(state, action){
      state.validatorsInfo = action.payload
    },
    setGetFeaturedModal(state, action){
      state.featuredModal = action.payload
    },
    setTransactionStep(state, action){
      state.transactionStep = action.payload
    },
    setTezosAccount(state, action){
      state.tezosAccount = action.payload
    },
    setKukaiWallet(state, action){
      state.kukaiWallet = action.payload
    },
    setTempleWallet(state, action){
      state.templeWallet = action.payload
    }
  },
});

export const {
    setTempleWallet,
    setKukaiWallet,
    setTezosAccount,
    setGetFeaturedModal,
    setTransactionStep,
    setValidatorsInf,
    setTransferLoaderModal,
    toggleNFTInfo,
    removeFromNotWhiteListed,
    setNFTsToWhitelist,
    setReset,
    setTo, 
    claimAlgorandPopup,
    setAlgorandClaimables,
    setFrom,
    setChainModal,
    setDepartureOrDestination,
    setChainSearch,
    setStep,
    setAccount,
    setMetaMask,
    setNFTList,
    setFactory,
    setSelectedNFTList,
    cleanSelectedNFTList,
    removeFromSelectedNFTList,
    setSearchNFTList,
    allSelected,
    setNFTsListView,
    updateApprovedNFTs,
    setApproved,
    setReceiver,
    setTxnHash,
    setWrongNetwork,
    setMetaMaskActive,
    setElrondAccount,
    removeAlgorandClaimable,
    setMaiarProvider,
    setOnMaiar,
    connectAlgorandWalletClaim,
    setTronWallet,
    setConfirmMaiarMob,
    setSwitchDestination,
    setAccountModal,
    setBigLoader,
    setApproveLoader,
    setTronLink,
    setOnWC,
    setWC,
    setWidget,
    setError,
    setBigNumFees,
    setTronPopUp,
    setTronLoginError,
    setTrustWallet,
    setAlgoSigner,
    setAlgorandAccount,
    setShowAbout,
    setShowVideo,
    setAlgorandWallet,
    setMyAlgo,
    cleanTxnHashArr
} = generalSlice.actions;

export default generalSlice.reducer;
